<template>
  <div>
    <header class="clearfix">
      <el-image
        style="margin-left: 40px; display: block; width: 50px; height: 50px"
        :src="require('@/assets/logo.png')"
      ></el-image>
      <span class="title">ASG赛事后台管理系统</span>
    </header>
    <main>
      <el-image class="img-wrap" :src="require('@/assets/manager.svg')">
      </el-image>
      <div class="loginwrap">
        <div>
          <div style="text-align: center">
            <p>系统登录</p>
          </div>
          <div class="info">
            <el-tabs tab-position="top" :stretch="true">
              <el-tab-pane label="用户名登录">
                <el-form
                  ref="ruleForm"
                  :model="userform"
                  :rules="rules"
                  class="demo-ruleForm"
                >
                  <el-form-item label="用户名" prop="username">
                    <el-input
                      v-model="userform.username"
                      placeholder="请输入用户名"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="password">
                    <el-input
                      v-model="userform.password"
                      :type="inputtype"
                      placeholder="请输入密码"
                      @keyup.enter.native="submit('ruleForm')"
                    >
                      <i
                        @click="toggleEye"
                        slot="suffix"
                        :class="eye_status"
                      ></i>
                    </el-input>
                  </el-form-item>
                </el-form>
                <el-button
                  type="primary"
                  :loading="loading"
                  style="width: 100%"
                  @click="submit('ruleForm')"
                  >登录</el-button
                >
              </el-tab-pane>
              <el-tab-pane label="邮箱登录">
                <el-form
                  ref="typeTwoForm"
                  :model="typeTwoForm"
                  :rules="rules2"
                  class="demo-ruleForm"
                >
                  <el-form-item label="邮箱地址" prop="email">
                    <el-input
                      v-model="typeTwoForm.email"
                      placeholder="请输入邮箱地址"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="密码" prop="password">
                    <el-input
                      v-model="typeTwoForm.password"
                      type="password"
                      @keyup.enter.native="submitByEmail('typeTwoForm')"
                      placeholder="请输入密码"
                      show-password
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <el-button
                  type="primary"
                  :loading="loading"
                  style="width: 100%"
                  @click="submitByEmail('typeTwoForm')"
                  >登录</el-button
                >
              </el-tab-pane>
              <el-tab-pane label="临时登录">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <el-button type="text" @click="$router.push('/index/ballot')"
                    >临时抽签</el-button
                  >
                  <el-button type="text" @click="$router.push('/index/gamepic')"
                    >临时对战图制作</el-button
                  >
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <p>Copyright © 2024 AvengerS Gaming</p>
    </footer>
  </div>
</template>

<script>
import { loginUser, loginByEmail } from "@/api/login/index";
import { getInfo } from "@/api/home";
export default {
  name: "Login",
  data() {
    return {
      userform: {
        username: "",
        password: "",
      },
      typeTwoForm: {
        email: "",
        password: "",
      },
      loading: false,
      eye_status: "el-icon-lock",
      inputtype: "password",
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      rules2: {
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      loginUser(this.userform)
        .then((res) => {
          this.$store.commit("setToken", res.data);
          this.initGetInfo();
          setTimeout(() => {
            this.$router.push("/index");
            this.$message.success("登录成功！");
            this.loading = false;
          }, 2000);
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response?.data?.message) {
            this.$message.error(err.response.data.message);
          } else {
            this.$message.error("服务端异常，无法登录，请使用临时抽签！");
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },
    initGetInfo() {
      getInfo()
        .then((res) => {
          this.$store.commit("getUserInfo", res.data);
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("baseImg", res.data.base64);
          sessionStorage.setItem("chinaname", res.data.chinaname);
          sessionStorage.setItem("officium", res.data.officium);
          sessionStorage.setItem("id", res.data.id);
          sessionStorage.setItem("isadmin", res.data.isadmin);
          sessionStorage.setItem("integral", res.data.integral);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleEye() {
      if (this.eye_status === "el-icon-lock") {
        this.eye_status = "el-icon-unlock";
        this.inputtype = "text";
      } else {
        this.eye_status = "el-icon-lock";
        this.inputtype = "password";
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          this.$message.warning("请完整输入账号和密码！");
        }
      });
    },
    submitByEmail(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            userEmail: this.typeTwoForm.email,
            password: this.typeTwoForm.password,
          };
          this.loading = true;
          loginByEmail(data)
            .then((res) => {
              this.$store.commit("setToken", res.data);
              this.initGetInfo();
              setTimeout(() => {
                this.$router.push("/index");
                this.$message.success("登录成功！");
                this.loading = false;
              }, 2000);
            })
            .catch((err) => {
              console.log("err", err);
              this.$message.error(err.response.data.message);
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
              }, 2000);
            });
        } else {
          this.$message.warning("请完整输入邮箱地址和密码！");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
@title-font: 1.5rem;
// 头部
header {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  img {
    margin-left: 40px;
    display: block;
    width: 50px;
    height: 50px;
  }
  span {
    color: #0089f3;
    display: block;
    font-weight: 700;
    line-height: 70px;
    height: 70px;
    font-size: @title-font;
  }
}
// 中间部分
main {
  width: 100%;
  height: 83vh;
  position: relative;
  overflow: hidden;
  background-image: url(../../assets/background.png);
  background-size: cover;
  .img-wrap {
    position: absolute;
    left: 12vw;
    top: 8vh;
    width: 32vw;
    height: 32vw;
  }
  .loginwrap {
    min-height: 45vh;
    width: 450px;
    border: 1px solid #ddd;
    background-color: white;
    padding-bottom: 20px;
    position: absolute;
    right: -501px;
    top: 10vh;
    border-radius: 4px;
    animation: forwards move 0.8s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32), 0 0 6px rgba(0, 0, 0, 0.24);
    }
    p {
      text-align: center;
      font-size: 22px;
      margin: 10px 0;
      i {
        color: orange;
      }
    }
    .info {
      width: 70%;
      margin: 0 auto;
      padding: 2%;
      .el-form-item {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        &:nth-child(2) {
          margin-bottom: 30px;
        }
        .el-form-item__label {
          line-height: 30px;
        }
        .el-input {
          i {
            line-height: 40px;
            font-size: 22px;
          }
        }
      }
    }
  }
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-600px);
    }
  }
}
// 底部
footer {
  height: calc(17vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 13px;
    color: #303030;
  }
}
</style>
