//引入nprogress
import nProgress from "nprogress";
import "nprogress/nprogress.css";
nProgress.inc(0.2);
nProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
import VueRouter from "vue-router";
import { getToken } from "@/utils/auth"; 
//引入组件
import Login from "../view/login/index.vue";
import Err from "../view/Err.vue"
//创建一个路由器
const router = new VueRouter({
  routes: [
    {
      path: "/",
      alias:"/login",
      name:"登录页",
      component: Login,
    },
    {
      path:"/404",
      name:"路径丢失",
      component:Err
    },
    {
      path: "/index",
      component: () => import("../view/homepage/index.vue"),
      name:"首页",
      children: [
        {
          path: "/",
          component: () => import("../view/newHome/index.vue"),
          name:"首页",
        },
        {
          path: "information",
          component: () => import("../view/homepage/Home.vue"),
          name:"个人信息",
        },
        {
          path:"drag",
          component: () => import("../view/drag/index.vue"),
          name:"晋升图"
        },
        {
          path: "person",
          component: () => import("../view/person/index.vue"),
          name:"人员管理"
        },
        {
          path: "schedule",
          component: () => import("../view/schedule/index.vue"),
          name:"赛程管理"
        },
        {
          path: "enroll",
          component: () => import("../view/enroll"),
          name:"报名管理"
        },
        {
          path:"blok",
          component: () => import('../view/blok'),
          name:"冠军发布"
        },
        {
          path:"news",
          component: () => import('../view/news'),
          name:"公告发布"
        },
        {
          path:"test",
          component: () => import("../view/test"),
          name:"导出操作"
        },
        {
          path:"ballot",
          component: () => import("../view/ballot/index.vue"),
          name:"抽签管理"
        },
        {
          path:"workflow",
          component: () => import("../view/workflow/index.vue"),
          name:"工作流"
        },
        // 导出赛程图
        {
          path:"gamepic",
          component: () => import("../view/gamepic/index.vue"),
          name:"对战图制作"
        },
        //发布邮箱
        {
          path:"sendEmail",
          component: () => import("../view/sendE/index.vue"),
          name:"邮箱发布"
        },
        // 黑白名单
        {
          path:"banpick",
          component: () => import("../view/banpick/index.vue"),
          name:"合作伙伴"
        },
        {
          path:"private",
          component: () => import("../view/private/index.vue"),
          name:"隐私政策"
        },
        {
          path:"globalVar",
          component: () => import("../view/global/index.vue"),
          name:"云端配置"
        },
        {
          path:"exchange",
          component: () => import("../view/exchange/index.vue"),
          name:"兑换中心"
        },
        {
          path:"process",
          component: () => import("../view/server/process.vue"),
          name:"服务器进程"
        }
      ],
    },
  ],
});

// 设置白名单，没有token也可以进入的页面
const whiteList = ['/','/404','/index/ballot','/index/gamepic']
// 设置路由前置守卫
router.beforeEach((to, from, next) => {
  console.log("🐕 ~ to:", to)
  // if如果为ture，证明有token,只要不跳转到登录页，去哪都行
  nProgress.start()
  if (getToken()) {
    // 只要路由发生了跳转，就可以执行进度条
    // 设置后置守卫，路由跳转成功就可以关闭进度条
      next(true)
      nProgress.done();
  } else {
    // 如果没有token，执行以下代码，
    // if判断要进入的页面是否需要token，
    if (whiteList.includes(to.path)) {
      next(true)
    } else {
      next('/404')
      nProgress.done();
    }
  }
})

router.afterEach(() => {
  nProgress.done();
});
export default router;
